import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import {CheckCircleOutlined, NumberOutlined, ReloadOutlined, WhatsAppOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, Form, Input, Skeleton, Space} from 'antd';
import {useForm} from 'antd/es/form/Form';
import _ from 'lodash';

import {MyModal} from '../../../../components/common/MyModal';
import {updateUser, updateUserVariables} from '../../../../shared/graphql/admin/__generated__/updateUser';
import {user, userVariables} from '../../../../shared/graphql/admin/__generated__/user';
import {QUERY_USER, UPDATE_USER} from '../../../../shared/graphql/admin/users';
import generatePassword from '../../../../shared/helpers/generatePassword';
import { phoneToPlain} from '../../../../shared/helpers/phoneFormat';
import {pwdRegex} from '../../../../shared/helpers/regex';

import {IUser,UserForm} from './UserForm';

import cls from '../Users.module.scss';

interface IGroupEdit {
    id: string
}

export const UserEdit = ({id}: IGroupEdit) => {

    const [openSuccess, setOpenSuccess] = useState<boolean>(false)
    const [openPwd, setOpenPwd] = useState<boolean>(false)
    const [activeUser, setActiveUser] = useState<any>(undefined)

    const [form] = useForm()

    const query = useQuery<user,userVariables>(QUERY_USER, {
        variables: {
            documentId: id,
        },
    })

    const initValues:IUser = {
        firstName: query.data?.usersPermissionsUser?.firstName!,
        lastName: query.data?.usersPermissionsUser?.lastName!,
        middleName: query.data?.usersPermissionsUser?.middleName!,
        username: query.data?.usersPermissionsUser?.username!,
        role: query.data?.usersPermissionsUser?.role?.name === 'Admin' ? 'admin' : 'parent',
        clients: _.map(query.data?.usersPermissionsUser?.clients, item => item?.documentId!),
    }

    const [updateUser, {loading}] = useMutation<updateUser,updateUserVariables>(UPDATE_USER)
    const navigate = useNavigate()

    const updateUserPwd = async (data:any) => {
        await updateUser({
            variables: {id: id, data: {password: data.password}},
            onCompleted: () => {
                setOpenPwd(false)
                setActiveUser({
                    ...activeUser,
                    password: data.password,
                    message: `Новый пароль для входа

Логин: ${initValues.username}
Пароль: ${data.password}`
                })
                setOpenSuccess(true)
            }
        })
    }

    const onFinish = async (data: IUser) => {
        await updateUser({
            variables: {
                id: id,
                data: {
                    ...data,
                    role: data.role === 'admin' ? '3' : '1',
                    username: phoneToPlain(data.username),
                },
            },
            onCompleted: () => {
                navigate(-1)
            },
        })
    }

    if (query.loading) return <Skeleton active/>

    return (
    <>
        <UserForm values={initValues} action={'Сохранить'} onFinish={onFinish}/>

        <Button icon={<NumberOutlined/>} onClick={() => setOpenPwd(true)}>Сменить пароль</Button>

        <MyModal isOpen={openPwd} onClose={() => setOpenPwd(false)} className={cls.modal}>
            <Space direction={'vertical'}>
                <Form layout={'vertical'} size={'large'} initialValues={{password: generatePassword()}} onFinish={updateUserPwd} disabled={loading}
                      form={form}>
                    <Form.Item name={'password'} label={'Пароль'} rules={[
                        {required: true, message: 'Заполните поле'},
                        {pattern: pwdRegex, message: 'Не соответствует формату пароля'}
                    ]}
                               extra={'Минимум 6 латинских букв и цифр'}
                    >
                        <Input
                            placeholder={'12345678'}
                            suffix={
                                <Button
                                    size={'small'}
                                    type={'text'}
                                    icon={<ReloadOutlined/>}
                                    onClick={() => form.setFieldValue('password', generatePassword())}
                                />}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType={'submit'} type={'primary'} loading={loading}>
                            Установить пароль
                        </Button>
                    </Form.Item>
                </Form>
            </Space>
        </MyModal>

        <MyModal isOpen={openSuccess} onClose={() => setOpenSuccess(false)} className={cls.modal}>
            <Space direction={'vertical'}>
                <div className={cls.icon}>
                    <CheckCircleOutlined/> Успешно
                </div>

                <div>
                    Пароль виден только на этом экране, посмотреть повторно не получится, но можно установить новый.
                    Отправьте сообщение с новым логином и паролем.
                </div>

                <pre className={cls.message}>
                        {activeUser && activeUser.message}
                    </pre>

                <a href={encodeURI(`https://api.whatsapp.com/send/?phone=${activeUser && activeUser.username}&text=${activeUser && activeUser.message}`)} target={'_blank'}
                   rel={'noreferrer'}>
                    <Button type={'primary'} size={'large'} onClick={() => setOpenSuccess(false)}
                            icon={<WhatsAppOutlined/>}>Написать в WhatsApp</Button>
                </a>

            </Space>
        </MyModal>

    </>
    )

}