import React, {useState} from 'react';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import type {SelectProps} from 'antd'
import {Button, DatePicker, Form, Input, Select} from 'antd'
import {MaskedInput} from 'antd-mask-input';
import dayjs from 'dayjs';
import _ from 'lodash';

import {selectGroups, selectGroupsVariables} from '../../../../shared/graphql/admin/__generated__/selectGroups';
import {selectUsers, selectUsersVariables} from '../../../../shared/graphql/admin/__generated__/selectUsers';
import {QUERY_SELECT_GROUPS} from '../../../../shared/graphql/admin/groups';
import {QUERY_SELECT_USERS} from '../../../../shared/graphql/admin/users';
import { phoneRegex } from '../../../../shared/helpers/regex';

import cls from '../../Groups/Group/Group.module.scss';


export interface IClient {
    firstName: string
    lastName: string
    middleName?: string
    phone: string
    group?: string
    birthday?: dayjs.Dayjs
    parents?: string[]
}

export interface IFormClient {
    values: IClient
    action: string
    onFinish: (data: IClient) => void
    loading?: boolean
}


export const ClientForm = ({values, action, onFinish, loading}: IFormClient) => {

    const [searchParent, setSearchParent] = useState<string>('')
    const [searchGroup, setSearchGroup] = useState<string>('')

    const groups = useQuery<selectGroups, selectGroupsVariables>(QUERY_SELECT_GROUPS, {
        variables: {
            pagination: {limit: 1000},
        }
    })

    const groupsOptions: SelectProps['options'] = _.map(groups.data?.groups, group => ({
        value: group?.documentId,
        label: group?.name,
    })).filter(item => item.label?.includes(searchGroup))

    const parents = useQuery<selectUsers, selectUsersVariables>(QUERY_SELECT_USERS, {
        variables: {
            pagination: {limit: 1000},
        }
    })

    const parentsOptions: SelectProps['options'] = _.map(parents.data?.usersPermissionsUsers, user => ({
            value: user?.documentId!,
            label: `${user?.firstName} ${user?.lastName}`,
        })
    ).filter(item => item.label?.includes(searchParent))

    return (
        <Form layout={'vertical'} size={'large'} initialValues={values} onFinish={onFinish} disabled={loading}>

            <Form.Item name={'firstName'} label={'Имя'} rules={[
                {required: true, message: 'Заполните поле'},
            ]}>
                <Input placeholder={'Иван'}/>
            </Form.Item>

            <Form.Item name={'lastName'} label={'Фамилия'} rules={[
                {required: true, message: 'Заполните поле'},
            ]}>
                <Input placeholder={'Петров'}/>
            </Form.Item>

            <Form.Item name={'middleName'} label={'Отчество'}>
                <Input placeholder={'Александрович'}/>
            </Form.Item>

            <Form.Item name={'phone'} label={'Номер в IIKO'} rules={[
                {required: true, message: 'Заполните поле'},
                {pattern: phoneRegex, message: 'Не соответствует формату номера телефона'}
            ]}>
                <MaskedInput mask={'+7 (000) 000-00-00'} placeholder={'+7 (999) 888-77-66'} size={'large'}/>
            </Form.Item>

            <Form.Item name={'birthday'} label={'Дата рождения'}>
                <DatePicker format={'DD.MM.YYYY'}/>
            </Form.Item>

            <Form.Item name={'group'} label={'Класс'}>
                <Select options={groupsOptions} showSearch filterOption={false} loading={groups.loading}
                        onSearch={(value) => setSearchGroup(value)}/>
            </Form.Item>

            <Form.Item label={'Родители'}>

                <Form.List name={'parents'}>
                    {(fields, {add, remove}, {errors}) => (
                        <>
                            {fields.map((field) => (
                                <React.Fragment key={field.key}>
                                    <div className={cls.client}>

                                        <Form.Item className={cls.select}
                                                   {...field}
                                                   rules={[
                                                       {
                                                           required: true,
                                                           message: 'Заполните поле',
                                                       },
                                                   ]}
                                        >
                                            <Select options={parentsOptions} filterOption={false} showSearch
                                                    onSearch={(value) => setSearchParent(value)}/>
                                        </Form.Item>
                                        <div className={cls.minus}>
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(field.name)}
                                            />
                                        </div>
                                    </div>

                                </React.Fragment>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    icon={<PlusOutlined/>}
                                />
                                <Form.ErrorList errors={errors}/>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

            </Form.Item>


            <Form.Item>
                <Button htmlType={'submit'} type={'primary'} loading={loading}>
                    {action}
                </Button>
            </Form.Item>

        </Form>
    )
}