import React from 'react';
import {Space} from 'antd';

import cls from './DisplayData.module.scss'

interface IDisplayData {
    label: string
    value: string|React.ReactNode
}

export const DisplayData = ({label, value}: IDisplayData) => (
    <Space direction="vertical" size={2}>
        <span className={cls.label}>{label}</span>
        <span className={cls.value}>{value}</span>
    </Space>
)
