import React from 'react';
import {Outlet} from 'react-router-dom'
import cn from 'classnames';

import {HeaderAdmin} from '../Header/HeaderAdmin';

import cls from './Layout.module.scss';

interface ILayout {
    children?: React.ReactNode
}

export const LayoutAdmin = ({children}: ILayout) => (
    <div className={cls.layout}>

        <HeaderAdmin/>

        <div className={cn([cls.content])}>
            <Outlet/>
            {children}
        </div>
        
    </div>
)