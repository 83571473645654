import {useNavigate} from 'react-router';
import {useMutation} from '@apollo/client';

import {createGroup, createGroupVariables} from '../../../../shared/graphql/admin/__generated__/createGroup';
import {CREATE_GROUP} from '../../../../shared/graphql/admin/groups';

import {GroupForm, IGroup} from './GroupForm';

export const GroupNew = () => {

    const initValues:IGroup = {
        name: '',
        clients: [],
    }

    const [createGroup] = useMutation<createGroup,createGroupVariables>(CREATE_GROUP)
    const navigate = useNavigate()

    const onFinish = async (data: IGroup) => {

        // console.log('data', data)

        await createGroup({
            variables: {data: data},
            onCompleted: () => {
                navigate(-1)
            },
        })
    }

    return <GroupForm values={initValues} action={'Сохранить'} onFinish={onFinish}/>

}