import {useNavigate} from 'react-router';
import {useMutation} from '@apollo/client';
import dayjs from 'dayjs';

import {createClient, createClientVariables} from '../../../../shared/graphql/admin/__generated__/createClient';
import {CREATE_CLIENT} from '../../../../shared/graphql/admin/clients';
import {phoneToPlain} from '../../../../shared/helpers/phoneFormat';

import {ClientForm, IClient} from './ClientForm';

export const ClientNew = () => {

    const initValues: IClient = {
        firstName: '',
        lastName: '',
        phone: '',
    }

    const [createClient] = useMutation<createClient, createClientVariables>(CREATE_CLIENT)
    const navigate = useNavigate()

    const onFinish = async (data: IClient) => {

        await createClient({
            variables: {
                data: {
                    ...data,
                    phone: phoneToPlain(data.phone),
                    birthday: data.birthday && dayjs(data.birthday).format('YYYY-MM-DD'),
                }
            },
            onCompleted: () => {
                navigate(-1)
            },
        })
    }

    return <ClientForm values={initValues} action={'Сохранить'} onFinish={onFinish}/>

}