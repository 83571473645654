import React from 'react';
import {useNavigate} from 'react-router';
import {Link, useSearchParams} from 'react-router-dom';
import {
    CheckCircleOutlined,
    ClockCircleOutlined, CloseCircleOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { useQuery} from '@apollo/client';
import {Button, DatePicker, Input, Space, Table, Tooltip} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import {ENUM_ORDER_IIKOSTATUS, ENUM_ORDER_PAYMENTSTATUS} from '../../../__generated__/global-types';
import {toAdminBalance, toAdminUser} from '../../../config/routes';
import {
    adminOrders,
    adminOrders_orders_client,
    adminOrdersVariables
} from '../../../shared/graphql/admin/__generated__/adminOrders';
import {QUERY_ADMIN_ORDERS} from '../../../shared/graphql/admin/orders';
import {currencyFormat} from '../../../shared/helpers/currencyFormat';
import {phonePlainToInput} from '../../../shared/helpers/phoneFormat';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters} from './filter';

import cls from './Orders.module.scss'

function paymentStatusDisplay (status:ENUM_ORDER_PAYMENTSTATUS) {
    switch (status) {
        case ENUM_ORDER_PAYMENTSTATUS.CREATED_1: return <Tooltip title={'Платеж создан'}><ClockCircleOutlined className={cls.warning}/></Tooltip>
        case ENUM_ORDER_PAYMENTSTATUS.PAID_2: return <Tooltip title={'Платеж оплачен'}><CheckCircleOutlined className={cls.success}/></Tooltip>
        case ENUM_ORDER_PAYMENTSTATUS.ERROR_3: return <Tooltip title={'Ошибка платежа'}><CloseCircleOutlined className={cls.error}/></Tooltip>
    }
}

function iikoStatusDisplay (status:ENUM_ORDER_IIKOSTATUS) {
    switch (status) {
        case ENUM_ORDER_IIKOSTATUS.AWAIT_0: return <Tooltip title={'Ожидает поступления оплаты'}>—</Tooltip>
        case ENUM_ORDER_IIKOSTATUS.CREATED_1: return <Tooltip title={'Ожидает пополнения'}><ClockCircleOutlined className={cls.warning}/></Tooltip>
        case ENUM_ORDER_IIKOSTATUS.PROCESSED_2: return <Tooltip title={'В обработке'}><ClockCircleOutlined className={cls.warning}/></Tooltip>
        case ENUM_ORDER_IIKOSTATUS.FILLED_3: return <Tooltip title={'Кошелек пополнен'}><CheckCircleOutlined className={cls.success}/></Tooltip>
        case ENUM_ORDER_IIKOSTATUS.ERROR_4: return <Tooltip title={'Ошибка пополнения кошелька'}><CloseCircleOutlined className={cls.error}/></Tooltip>
    }
}

export const Orders = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);
    const navigate = useNavigate();

    const query = useQuery<adminOrders, adminOrdersVariables>(QUERY_ADMIN_ORDERS, {
        variables: {
            filters: filtersToQuery(filters),
            pagination: {limit: 1000},
            sort: ['createdAt:DESC'],
        },
        fetchPolicy: 'no-cache',
    })

    const onSearchChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const dataSource = _.map(query.data?.orders, item => ({
        ...item,
        key: item?.documentId!,
        createdAt: dayjs(item?.createdAt).format('DD.MM.YYYY HH:mm')
    }))

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'UID',
            dataIndex: 'UID',
            key: 'UID',
        },
        {
            title: 'Ученик',
            dataIndex: 'client',
            key: 'client',
            render: (value:adminOrders_orders_client) => <Link to={toAdminBalance(value.documentId)}>{value.firstName} {value.lastName}</Link>
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            render: (value:string) => phonePlainToInput(value)
        },
        {
            title: 'Сумма',
            dataIndex: 'amount',
            key: 'amount',
            render: (value:number) => currencyFormat(value)
        },
        {
            title: 'Статус оплаты',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            className: cls.align,
            render: (status:ENUM_ORDER_PAYMENTSTATUS) => paymentStatusDisplay(status),
        },
        {
            title: 'Статус пополнения',
            dataIndex: 'iikoStatus',
            key: 'iikoStatus',
            className: cls.align,
            render: (status:ENUM_ORDER_IIKOSTATUS) => iikoStatusDisplay(status),
        },
    ];

    const onPeriodChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    return (
        <div className={'admin-page'}>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={false}
                                                value={[filters.period[0], filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search placeholder={'Поиск...'} onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <Button type={'primary'} icon={<PlusOutlined/>}
                            onClick={() => navigate(toAdminUser('new'))}>Добавить</Button>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}/>

        </div>
    )

}