import React from 'react';
import {useNavigate} from 'react-router';
import {Link, useSearchParams} from 'react-router-dom';
import {EditOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, Input, Space, Table, Tag} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import {toAdminBalance, toAdminClient, toAdminUser} from '../../../config/routes';
import {
    clients,
    clients_clients_parents,
    clientsVariables
} from '../../../shared/graphql/admin/__generated__/clients';
import {updateClient, updateClientVariables} from '../../../shared/graphql/admin/__generated__/updateClient';
import {QUERY_CLIENTS, UPDATE_CLIENT} from '../../../shared/graphql/admin/clients';
import {phonePlainToInput} from '../../../shared/helpers/phoneFormat';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters} from './filter';

import cls from './Clients.module.scss'

export const Clients = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);
    const navigate = useNavigate();

    const [updateClient] = useMutation<updateClient, updateClientVariables>(UPDATE_CLIENT)

    const query = useQuery<clients, clientsVariables>(QUERY_CLIENTS, {
        variables: {
            filters: filtersToQuery(filters),
            pagination: {limit: 1000},
            sort: ['enabled:DESC', 'lastName:ASC'],
        },
        fetchPolicy: 'no-cache',
    })

    const onSearchChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const dataSource = _.map(query.data?.clients, item => ({
        ...item,
        key: item?.documentId,
        name: `${item?.firstName} ${item?.lastName}`,
        birthday: item?.birthday ? dayjs(item.birthday).format('DD.MM.YYYY') : '—',
    }))

    const updateClientEnabled = async (id: string, enabled: boolean) => {

        await updateClient({
            variables: {id: id, data: {enabled: enabled}},
            onCompleted: () => {
                query.refetch()
            }
        })

    }

    const columns = [
        {
            title: 'Имя Фамилия',
            dataIndex: 'name',
            key: 'name',
            render: (item: any, record: any) => {
                const element = record.phone ? <Link to={toAdminBalance(record.documentId)}>{item}</Link> : item
                return record.enabled ? element : <span className={cls.hided}>{element}</span>
            },
        },
        {
            title: 'Дата рождения',
            dataIndex: 'birthday',
            key: 'birthday',
            render: (value: string, record: any) => (
                record.enabled ? value : <span className={cls.hided}>{value}</span>
            ),
        },
        {
            title: 'Номер в IIKO',
            dataIndex: 'phone',
            key: 'phone',
            render: (phone: string, record: any) => (
                record.enabled ? phonePlainToInput(phone) :
                    <span className={cls.hided}>{phonePlainToInput(phone)}</span>
            ),
        },
        {
            title: 'Родители',
            dataIndex: 'parents',
            key: 'parents',
            render: (parents: clients_clients_parents[]) => (
                <>{_.map(parents, parent =>
                    <Link key={parent.documentId} to={toAdminUser(parent.documentId)}>
                        <Tag>
                            {parent.firstName} {parent.lastName}
                        </Tag>
                    </Link>
                )}
                </>),
        },
        {
            key: 'control',
            render: (_: any, record: any) => (
                <Space className={!record.enabled && cls.hided}>
                    <Button type={'link'} onClick={() => navigate(toAdminClient(record.documentId))}
                            icon={<EditOutlined/>}/>
                    {record.enabled ?
                        <Button type={'link'} onClick={() => updateClientEnabled(record.documentId, false)}
                                icon={<EyeOutlined/>}/>
                        :
                        <Button type={'link'} onClick={() => updateClientEnabled(record.documentId, true)}
                                icon={<EyeInvisibleOutlined/>}/>
                    }
                </Space>
            )
        },

    ];

    return (
        <div className={'admin-page'}>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <Input.Search placeholder={'Поиск...'} onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <Button type={'primary'} icon={<PlusOutlined/>}
                            onClick={() => navigate(toAdminClient('new'))}>Добавить</Button>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}/>

        </div>
    )

}