
import { UsersPermissionsUserFiltersInput} from '../../../__generated__/global-types';

export interface IUsersFilters {
    search: string | null
    // file: string | null
    // period: Dayjs[]
}

export const defaultFilter: IUsersFilters = {
    search: null,
    // file: null,
    // period: [dayjs().subtract(1, 'week'), dayjs()],
}

export const isChangedFilter = (filter: IUsersFilters) => {
    if (filter.search !== defaultFilter.search) return true
    // if (filter.file !== defaultFilter.file) return true
    // if (filter.period !== defaultIndexFilter.period) return true
}

export const filtersToSearchParams = (filter: IUsersFilters) => ({
    ...(filter.search && {search: filter.search}),
    // ...(filter.file && {file: filter.file}),
    // ...(filter.period && {start: filter.period[0].format('YYYY-MM-DD'), end: filter.period[1].format('YYYY-MM-DD')}),
})

export const searchParamsToFilters = (params: URLSearchParams) => ({
    search: params.get('search') ? params.get('search') : defaultFilter.search,
    // file: params.get('file') ? params.get('file') : defaultFilter.file,
    // period: params.get('start') && params.get('end') ? [dayjs(params.get('start')), dayjs(params.get('end'))] : defaultIndexFilter.period,
}) as IUsersFilters

export const filtersToQuery = (filter: IUsersFilters) => ({
    ...(filter.search && {
        or: [
            {firstName: {contains: filter.search}},
            {lastName: {contains: filter.search}},
            {username: {contains: filter.search}},
        ],

    }),

    // ...(filter.period && {createdAt: {between: [filter.period[0].format(), filter.period[1].format()]}}),
}) as UsersPermissionsUserFiltersInput

