import React, {useState} from 'react';
import {MinusCircleOutlined, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {useLazyQuery, useQuery} from '@apollo/client';
import type {SelectProps} from 'antd'
import {Button, Form, Input, Select} from 'antd'
import {useForm} from 'antd/es/form/Form';
import _ from 'lodash';

import {checkLogin, checkLoginVariables} from '../../../../shared/graphql/admin/__generated__/checkLogin';
import {selectClients, selectClientsVariables} from '../../../../shared/graphql/admin/__generated__/selectClients';
import {QUERY_SELECT_CLIENTS} from '../../../../shared/graphql/admin/clients';
import {CHECK_LOGIN} from '../../../../shared/graphql/admin/users';
import generatePassword from '../../../../shared/helpers/generatePassword';
import {phoneToPlain} from '../../../../shared/helpers/phoneFormat';
import {loginRegex, pwdRegex} from '../../../../shared/helpers/regex';

import cls from '../../Groups/Group/Group.module.scss';

export interface IUser {
    firstName: string
    lastName: string
    middleName?: string
    username: string
    password?: string
    role: 'admin' | 'parent'
    clients?: string[]
}

export interface IFormUser {
    pwd?: boolean
    values: IUser
    action: string
    onFinish: (data: IUser) => void
    loading?: boolean
}

export const UserForm = ({values, action, onFinish, loading, pwd}: IFormUser) => {

    const [searchClient, setSearchClient] = useState<string>('')

    const clients = useQuery<selectClients, selectClientsVariables>(QUERY_SELECT_CLIENTS, {
        variables: {
            filters: {enabled: {eq: true},},
            pagination: {limit: 5000},
        }
    })

    const [checkLogin] = useLazyQuery<checkLogin,checkLoginVariables>(CHECK_LOGIN)

    const clientsOptions: SelectProps['options'] = _.map(clients.data?.clients, client => ({
        value: client?.documentId,
        label: `${client?.firstName} ${client?.lastName}`,
    })).filter(item => item.label.includes(searchClient))

    const [form] = useForm()


    return (
        <Form layout={'vertical'} size={'large'} initialValues={values} onFinish={onFinish} disabled={loading}
              form={form}>

            <Form.Item name={'firstName'} label={'Имя'} rules={[
                {required: true, message: 'Заполните поле'},
            ]}>
                <Input placeholder={'Иван'}/>
            </Form.Item>

            <Form.Item name={'lastName'} label={'Фамилия'} rules={[
                {required: true, message: 'Заполните поле'},
            ]}>
                <Input placeholder={'Петров'}/>
            </Form.Item>

            <Form.Item name={'middleName'} label={'Отчество'}>
                <Input placeholder={'Александрович'}/>
            </Form.Item>

            <Form.Item name={'role'} label={'Роль'} rules={[
                {required: true, message: 'Заполните поле'},
            ]}>
                <Select options={[
                    {value: 'parent', label: 'Родитель'},
                    {value: 'admin', label: 'Администратор'},
                ]}/>
            </Form.Item>

            <Form.Item name={'username'} label={'Логин'} validateDebounce={1000} hasFeedback validateFirst rules={[
                {required: true, message: 'Заполните поле'},
                {pattern: loginRegex, message: 'Не соответствует формату логина'},
                { validator: async (_, value) => {
                    if (value !== values.username) {
                        const res = await checkLogin({variables: {username: phoneToPlain(value)}})
                        if (res.data?.checkLogin) {
                            return Promise.resolve()
                        } else {
                            return Promise.reject(new Error('Такой логин уже занят'))
                        }
                    } else {
                        return Promise.resolve()
                    }
                    }}
            ]}>
                <Input placeholder={'79998887766'} size={'large'}/>
            </Form.Item>

            {pwd &&
                <Form.Item name={'password'} label={'Пароль'} rules={[
                    {required: true, message: 'Заполните поле'},
                    {pattern: pwdRegex, message: 'Не соответствует формату пароля'}
                ]}
                           extra={'Минимум 6 латинских букв и цифр'}
                >
                    <Input
                        placeholder={'12345678'}
                        suffix={
                            <Button
                                size={'small'}
                                type={'text'}
                                icon={<ReloadOutlined/>}
                                onClick={() => form.setFieldValue('password', generatePassword())}
                            />}
                    />
                </Form.Item>

            }

            <Form.Item label={'Дети'}>

                <Form.List name={'clients'}>
                    {(fields, {add, remove}, {errors}) => (
                        <>
                            {fields.map((field) => (
                                <React.Fragment key={field.key}>
                                    <div className={cls.client}>

                                        <Form.Item className={cls.select}
                                                   {...field}
                                                   rules={[
                                                       {
                                                           required: true,
                                                           message: 'Заполните поле',
                                                       },
                                                   ]}
                                        >
                                            <Select options={clientsOptions} filterOption={false} showSearch
                                                    onSearch={(value) => setSearchClient(value)}/>
                                        </Form.Item>
                                        <div className={cls.minus}>
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(field.name)}
                                            />
                                        </div>
                                    </div>

                                </React.Fragment>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    icon={<PlusOutlined/>}
                                />
                                <Form.ErrorList errors={errors}/>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

            </Form.Item>


            <Form.Item>
                <Button htmlType={'submit'} type={'primary'} loading={loading}>
                    {action}
                </Button>
            </Form.Item>

        </Form>
    )
}