import {useNavigate} from 'react-router';
import {useMutation, useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import {client, clientVariables} from '../../../../shared/graphql/admin/__generated__/client';
import {updateClient, updateClientVariables} from '../../../../shared/graphql/admin/__generated__/updateClient';
import {QUERY_CLIENT, UPDATE_CLIENT} from '../../../../shared/graphql/admin/clients';
import {phonePlainToInput, phoneToPlain} from '../../../../shared/helpers/phoneFormat';

import {ClientForm, IClient} from './ClientForm';

interface IGroupEdit {
    id: string
}

export const ClientEdit = ({id}: IGroupEdit) => {

    const query = useQuery<client,clientVariables>(QUERY_CLIENT, {
        variables: {
            documentId: id,
        },
    })

    const initValues:IClient = {
        firstName: query.data?.client?.firstName!,
        lastName: query.data?.client?.lastName!,
        middleName: query.data?.client?.middleName!,
        phone: query.data?.client?.phone! && phonePlainToInput(query.data?.client?.phone!),
        group: query.data?.client?.group?.documentId!,
        birthday: query.data?.client?.birthday && dayjs(query.data?.client?.birthday),
        parents: _.map(query.data?.client?.parents, item => item?.documentId!),
    }

    const [updateClient] = useMutation<updateClient,updateClientVariables>(UPDATE_CLIENT)
    const navigate = useNavigate()

    const onFinish = async (data: IClient) => {
        await updateClient({
            variables: {
                id: id,
                data: {
                    ...data,
                    phone: phoneToPlain(data.phone),
                    birthday: data.birthday && dayjs(data.birthday).format('YYYY-MM-DD'),
                },
            },
            onCompleted: () => {
                navigate(-1)
            },
        })
    }

    if (query.loading) return <Skeleton active/>

    return <ClientForm values={initValues} action={'Сохранить'} onFinish={onFinish}/>

}