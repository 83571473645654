import {useState} from 'react';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router';
import {CheckCircleOutlined, WhatsAppOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {Button, Space} from 'antd';
import {observer} from 'mobx-react-lite';

import {MyModal} from '../../../../components/common/MyModal';
import {Store} from '../../../../model/store/Store';
import {createUser, createUserVariables} from '../../../../shared/graphql/admin/__generated__/createUser';
import {CREATE_USER} from '../../../../shared/graphql/admin/users';
import generatePassword from '../../../../shared/helpers/generatePassword';
import {phoneToPlain} from '../../../../shared/helpers/phoneFormat';

import {IUser,UserForm} from './UserForm';

import cls from './User.module.scss'

export const UserNew = observer(() => {

    const [message, setMessage] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    // const [password, setPassword] = useState<string>('')
    const [open, setOpen] = useState<boolean>(false)

    const {auth} = useInstance(Store)

    const initValues: IUser = {
        firstName: '',
        lastName: '',
        username: '',
        password: generatePassword(),
        role: 'parent',
    }

    const [createUser] = useMutation<createUser, createUserVariables>(CREATE_USER)
    const navigate = useNavigate()

    const onFinish = async (data: IUser) => {

        await createUser({
            variables: {
                data: {
                    ...data,
                    username: phoneToPlain(data.username),
                    email: `${phoneToPlain(data.username)}@proschoolnsk.ru`,
                    role: data.role === 'admin' ? '3' : '1',
                    provider: 'local',
                }
            },
            onCompleted: () => {
                // setLogin(data.username)
                // setPassword(data.password!)
                setMessage(`Добрый день! Меня зовут ${auth.data?.firstName}, команда Proschool. 
Для пополнения баланса питания ребенка вам необходимо перейти по ссылке:
https://class.proschoolnsk.ru

Логин: ${data.username}
Пароль: ${data.password}

Инструкция по оплате питания https://class.proschoolnsk.ru/instruction.pdf`)
                setPhone(phoneToPlain(data.username))
                setOpen(true)
            },
        })
    }

    return <>
        <UserForm values={initValues} action={'Сохранить'} onFinish={onFinish} pwd/>
        <MyModal isOpen={open} onClose={() => navigate(-1)} className={cls.modal}>
            <Space direction={'vertical'}>
                <div className={cls.icon}>
                    <CheckCircleOutlined/> Успешно
                </div>

                <div>
                    Пароль виден только на этом экране, посмотреть повторно не получится, но можно установить новый.
                    Отправьте приветственное сообщение с логином и паролем.
                </div>

                <pre className={cls.message}>
                    {message}
                </pre>

                <a href={encodeURI(`https://api.whatsapp.com/send/?phone=${phone}&text=${message}`)} target={'_blank'} rel={'noreferrer'}>
                    <Button type={'primary'} size={'large'} onClick={() => navigate(-1)} icon={<WhatsAppOutlined/>}>Написать в WhatsApp</Button>
                </a>

            </Space>
        </MyModal>
        </>

})