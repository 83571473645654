import {useInstance} from 'react-ioc';
import {Link} from 'react-router-dom';
import type {MenuProps} from 'antd';
import {Avatar, Dropdown} from 'antd';
import cn from 'classnames'
import {observer} from 'mobx-react-lite';

import {
    INDEX
} from '../../config/routes';
import {Store} from '../../model/store/Store';

import logo from './Logo.svg'

import cls from './Header.module.scss';


export const Header = observer(() => {

    const {auth, client} = useInstance(Store);

    const userMenu: MenuProps['items'] = [
        {
            key: 'logout',
            label: 'Выйти',
            onClick: () => {
                client.clearState();
                auth.clearState();
            },
        },
    ]

    return (
        <div className={cls.wrapper}>
            <div className={cn(['content', cls.header])}>
                <div>
                    <Dropdown menu={{items: userMenu}} trigger={['click']}>
                        <Avatar>{auth.data?.firstName[0]}{auth.data?.lastName[0]}</Avatar>
                    </Dropdown>
                </div>
                <div className={cls.logo}>
                    <Link to={INDEX}>
                        <img className={cls.logo} src={logo} alt={'Pro School Logo'}/>
                    </Link>
                </div>
            </div>
        </div>
    )
})