import {ApolloClient, ApolloLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {createUploadLink} from 'apollo-upload-client';

import {getApiBase} from '../../env';
import {IRootStoreModel} from '../store/RootStore';

const API_BASE = getApiBase();

const ApiClientFactory = (store: IRootStoreModel) => {

    const errorLink = onError(({graphQLErrors, networkError, operation}) => {
        const {response} = operation.getContext();
        if (response && response.status === 401) {
            // Если токен сдох, то разлогиниваем пользователя
            store.auth.clearState();
        }

        if (graphQLErrors) {
            graphQLErrors.forEach((error) =>
                console.log(`[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,)
            );
        }

        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
        }


    });

    const authLink = setContext((_, {headers}) => {
        if (!store.auth.token) {
            return headers;
        }
        const token = store.auth.token;

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    });

    const uploadLink = createUploadLink({
        uri: `${API_BASE}/graphql`,
    });

    return new ApolloClient({
        link: ApolloLink.from([errorLink, authLink, uploadLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        }
    });
};

export default ApiClientFactory;
