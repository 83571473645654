import React, {ReactNode} from 'react';
import Sheet from 'react-modal-sheet';
import { Modal } from 'antd';

import cls from './MyModal.module.scss'

interface IMyModal {
    isOpen: boolean
    onClose:  () => void
    children: ReactNode
    className?: string
}

export const MyModal = ({
    isOpen,
    onClose,
    children,
    className,
}: IMyModal) => {
    const isSmallScreenWidth = window.innerWidth <= 425;

    if (isSmallScreenWidth) {
        return (
            <Sheet isOpen={isOpen} onClose={onClose}>
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content className={cls.content}>
                        {children}
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>
        );
    }

    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            footer={null}
            className={className}
        >
            {children}
        </Modal>
    );
}