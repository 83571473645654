import _ from 'lodash';
import {applySnapshot, types as t,} from 'mobx-state-tree';

export const initialClientStore = {
    clients: [],
    selectedClient: null,
};

export interface ICLient {
    id: string
    firstName: string
    lastName: string
    phone: string
    group?: string
    birthday?: string
}

export const ClientStore = t
    .model({
        clients: t.array(
            t.model({
                id: t.string,
                firstName: t.string,
                lastName: t.string,
                phone: t.string,
                group: t.maybeNull(t.string),
                birthday: t.maybeNull(t.string),
            })
        ),
        selectedClient: t.maybeNull(t.string),
    })
    .views((self => ({
        clientData(id: string) {
            const item = _.find(self.clients, {id: id})
            return item ? item : null
        },
        currentClient() {
            return _.find(self.clients, {id: self.selectedClient}) || {
                id: '',
                firstName: '',
                lastName: '',
                phone: '',
                group: '',
            }
        },
    })))
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialClientStore);
        },
        setClients: (data: any) => {
            self.clients = data;
            self.selectedClient = data[0].id;
        },
        selectDomain: (id: string) => {
            self.selectedClient = id
        }
    }));
