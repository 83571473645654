/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_ORDER_IIKOSTATUS {
  AWAIT_0 = "AWAIT_0",
  CREATED_1 = "CREATED_1",
  ERROR_4 = "ERROR_4",
  FILLED_3 = "FILLED_3",
  PROCESSED_2 = "PROCESSED_2",
}

export enum ENUM_ORDER_PAYMENTSTATUS {
  CREATED_1 = "CREATED_1",
  ERROR_3 = "ERROR_3",
  PAID_2 = "PAID_2",
}

export interface BooleanFilterInput {
  and?: (boolean | null)[] | null;
  or?: (boolean | null)[] | null;
  not?: BooleanFilterInput | null;
  eq?: boolean | null;
  eqi?: boolean | null;
  ne?: boolean | null;
  nei?: boolean | null;
  startsWith?: boolean | null;
  endsWith?: boolean | null;
  contains?: boolean | null;
  notContains?: boolean | null;
  containsi?: boolean | null;
  notContainsi?: boolean | null;
  gt?: boolean | null;
  gte?: boolean | null;
  lt?: boolean | null;
  lte?: boolean | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (boolean | null)[] | null;
  notIn?: (boolean | null)[] | null;
  between?: (boolean | null)[] | null;
}

export interface ClientFiltersInput {
  documentId?: IDFilterInput | null;
  firstName?: StringFilterInput | null;
  lastName?: StringFilterInput | null;
  phone?: StringFilterInput | null;
  group?: GroupFiltersInput | null;
  parents?: UsersPermissionsUserFiltersInput | null;
  enabled?: BooleanFilterInput | null;
  middleName?: StringFilterInput | null;
  birthday?: DateFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  locale?: StringFilterInput | null;
  localizations?: ClientFiltersInput | null;
  and?: (ClientFiltersInput | null)[] | null;
  or?: (ClientFiltersInput | null)[] | null;
  not?: ClientFiltersInput | null;
}

export interface ClientInput {
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  group?: string | null;
  parents?: (string | null)[] | null;
  enabled?: boolean | null;
  middleName?: string | null;
  birthday?: any | null;
  publishedAt?: any | null;
  locale?: string | null;
}

export interface DateFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: DateFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  nei?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface DateTimeFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: DateTimeFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  nei?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface FloatFilterInput {
  and?: (number | null)[] | null;
  or?: (number | null)[] | null;
  not?: FloatFilterInput | null;
  eq?: number | null;
  eqi?: number | null;
  ne?: number | null;
  nei?: number | null;
  startsWith?: number | null;
  endsWith?: number | null;
  contains?: number | null;
  notContains?: number | null;
  containsi?: number | null;
  notContainsi?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (number | null)[] | null;
  notIn?: (number | null)[] | null;
  between?: (number | null)[] | null;
}

export interface GroupFiltersInput {
  documentId?: IDFilterInput | null;
  name?: StringFilterInput | null;
  clients?: ClientFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  locale?: StringFilterInput | null;
  localizations?: GroupFiltersInput | null;
  and?: (GroupFiltersInput | null)[] | null;
  or?: (GroupFiltersInput | null)[] | null;
  not?: GroupFiltersInput | null;
}

export interface GroupInput {
  name?: string | null;
  clients?: (string | null)[] | null;
  publishedAt?: any | null;
  locale?: string | null;
}

export interface IDFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: IDFilterInput | null;
  eq?: string | null;
  eqi?: string | null;
  ne?: string | null;
  nei?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface JSONFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: JSONFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  nei?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface OrderFiltersInput {
  documentId?: IDFilterInput | null;
  UID?: StringFilterInput | null;
  phone?: StringFilterInput | null;
  amount?: FloatFilterInput | null;
  client?: ClientFiltersInput | null;
  paymentStatus?: StringFilterInput | null;
  paymentLink?: StringFilterInput | null;
  paymentObject?: JSONFilterInput | null;
  iikoStatus?: StringFilterInput | null;
  iikoObject?: JSONFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  locale?: StringFilterInput | null;
  localizations?: OrderFiltersInput | null;
  and?: (OrderFiltersInput | null)[] | null;
  or?: (OrderFiltersInput | null)[] | null;
  not?: OrderFiltersInput | null;
}

export interface OrderInput {
  UID?: string | null;
  phone?: string | null;
  amount?: number | null;
  client?: string | null;
  paymentStatus?: ENUM_ORDER_PAYMENTSTATUS | null;
  paymentLink?: string | null;
  paymentObject?: any | null;
  iikoStatus?: ENUM_ORDER_IIKOSTATUS | null;
  iikoObject?: any | null;
  publishedAt?: any | null;
  locale?: string | null;
}

export interface PaginationArg {
  page?: number | null;
  pageSize?: number | null;
  start?: number | null;
  limit?: number | null;
}

export interface StringFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: StringFilterInput | null;
  eq?: string | null;
  eqi?: string | null;
  ne?: string | null;
  nei?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface UsersPermissionsLoginInput {
  identifier: string;
  password: string;
  provider: string;
}

export interface UsersPermissionsPermissionFiltersInput {
  documentId?: IDFilterInput | null;
  action?: StringFilterInput | null;
  role?: UsersPermissionsRoleFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  locale?: StringFilterInput | null;
  localizations?: UsersPermissionsPermissionFiltersInput | null;
  and?: (UsersPermissionsPermissionFiltersInput | null)[] | null;
  or?: (UsersPermissionsPermissionFiltersInput | null)[] | null;
  not?: UsersPermissionsPermissionFiltersInput | null;
}

export interface UsersPermissionsRoleFiltersInput {
  documentId?: IDFilterInput | null;
  name?: StringFilterInput | null;
  description?: StringFilterInput | null;
  type?: StringFilterInput | null;
  permissions?: UsersPermissionsPermissionFiltersInput | null;
  users?: UsersPermissionsUserFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  locale?: StringFilterInput | null;
  localizations?: UsersPermissionsRoleFiltersInput | null;
  and?: (UsersPermissionsRoleFiltersInput | null)[] | null;
  or?: (UsersPermissionsRoleFiltersInput | null)[] | null;
  not?: UsersPermissionsRoleFiltersInput | null;
}

export interface UsersPermissionsUserFiltersInput {
  documentId?: IDFilterInput | null;
  username?: StringFilterInput | null;
  email?: StringFilterInput | null;
  provider?: StringFilterInput | null;
  password?: StringFilterInput | null;
  resetPasswordToken?: StringFilterInput | null;
  confirmationToken?: StringFilterInput | null;
  confirmed?: BooleanFilterInput | null;
  blocked?: BooleanFilterInput | null;
  role?: UsersPermissionsRoleFiltersInput | null;
  firstName?: StringFilterInput | null;
  lastName?: StringFilterInput | null;
  clients?: ClientFiltersInput | null;
  middleName?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  locale?: StringFilterInput | null;
  localizations?: UsersPermissionsUserFiltersInput | null;
  and?: (UsersPermissionsUserFiltersInput | null)[] | null;
  or?: (UsersPermissionsUserFiltersInput | null)[] | null;
  not?: UsersPermissionsUserFiltersInput | null;
}

export interface UsersPermissionsUserInput {
  username?: string | null;
  email?: string | null;
  provider?: string | null;
  password?: string | null;
  resetPasswordToken?: string | null;
  confirmationToken?: string | null;
  confirmed?: boolean | null;
  blocked?: boolean | null;
  role?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  clients?: (string | null)[] | null;
  middleName?: string | null;
  publishedAt?: any | null;
  locale?: string | null;
}

export interface iikoTransactionsInput {
  phone: string;
  dateFrom: any;
  dateTo: any;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
