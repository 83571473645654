import {gql} from '@apollo/client';

export const QUERY_IIKO_BALANCE = gql`
    query iikoBalance ($phone: String!) {
        iikoBalance(phone: $phone) {
            balance
            deleted
            blocked
        }
    }
`;

export const QUERY_ORDERS = gql`
    query orders ($filters: OrderFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        orders(filters: $filters, pagination: $pagination, sort: $sort) {
            documentId
            createdAt
            amount
        }
    }
`;

export const QUERY_IIKO_TRANSACTIONS = gql`
    query iikoTransactions ($data: iikoTransactionsInput!) {
        iikoTransactions(data: $data) {
            type
            transactionDate
            transactionSum
            orderDate
            orderNumber
            orderSum
            comment
        }
    }
`;

export const MUTATION_CREATE_ORDER = gql`
    mutation createOrder ($data: OrderInput!) {
        createOrder(data: $data) {
            paymentLink
        }
    }
`;