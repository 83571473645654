import {useEffect, useState } from 'react';
import {useInstance} from 'react-ioc';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {MenuOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Avatar, Button, Dropdown, Space} from 'antd';
import cn from 'classnames'
import _ from 'lodash';
import {observer} from 'mobx-react-lite';

import {
ADMIN_CLIENTS,     ADMIN_GROUPS, ADMIN_ORDERS, ADMIN_USERS,
    INDEX
} from '../../config/routes';
import {Store} from '../../model/store/Store';

import logo from './Logo.svg'

import cls from './Header.module.scss';


export const HeaderAdmin = observer(() => {

    const {auth, client} = useInstance(Store);
    const location = useLocation()

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const userMenu: MenuProps['items'] = [
        {
            key: 'logout',
            label: 'Выйти',
            onClick: () => {
                client.clearState();
                auth.clearState();
            },
        },
    ]

     const pagesMenu = [
        {
            link: ADMIN_GROUPS,
            label: 'Классы',
            selected: location.pathname === ADMIN_GROUPS,
        },
        {
            link: ADMIN_CLIENTS,
            label: 'Ученики',
            selected: location.pathname === ADMIN_CLIENTS,
        },
        {
            link: ADMIN_USERS,
            label: 'Родители',
            selected: location.pathname === ADMIN_USERS,
        },
        {
            link: ADMIN_ORDERS,
            label: 'Оплата питания',
            selected: location.pathname === ADMIN_ORDERS,
        },
    ]

    const items: MenuProps['items'] = _.map(pagesMenu, item => ({
        key: item.link,
        label: (
            <Link to={item.link}>{item.label}</Link>
        ),
    }))


    return (
        <div className={cls.wrapper}>
            <div className={cn([cls.header, cls.admin])}>
                <div>
                    <Dropdown menu={{items: userMenu}} trigger={['click']}>
                        <Space>
                            <Avatar>{auth.data?.firstName[0]}{auth.data?.lastName[0]}</Avatar>
                            <span>{auth.data?.firstName} {auth.data?.lastName}</span>
                        </Space>
                    </Dropdown>
                </div>
                <div>
                    <Space size={24}>
                        {width >= 768 && _.map(pagesMenu, item => (
                            <Link key={item.link} to={item.link} className={cn([cls.link, item.selected && cls.selected])}>{item.label}</Link>
                        ))}
                        {width < 768 &&
                            <Dropdown menu={{selectedKeys:[location.pathname], items: items}}>
                                <Button type={'primary'} icon={<MenuOutlined/>}/>
                            </Dropdown>
                        }
                        <div className={cls.logo}>
                            <Link to={INDEX}>
                                <img className={cls.logo} src={logo} alt={'Pro School Logo'}/>
                            </Link>
                        </div>
                    </Space>

                </div>
            </div>
        </div>
    )
})