// INDEX
export const ROOT = '';
export const INDEX = '/';


export const ADMIN = '/admin';
export const CLASSES = '/admin/classes';
export const STUDENTS = '/admin/students';
export const USERS = '/admin/users';
export const ORDERS = '/admin/orders';

export const LOGIN = '/login';
export const FORGET = '/login/forget';

export const NOT_FOUND = '/404';

export const INFO_CATERING = '/info/catering';
export const INFO_PAYMENT = '/info/payment';
export const PAYMENT_AWAIT = '/payment/await';


export const ADMIN_GROUPS = '/admin/groups';
export const ADMIN_GROUP = '/admin/group/:id';
export const toAdminGroup = (id: string) => `/admin/group/${id}`
export const ADMIN_CLIENTS = '/admin/students';
export const ADMIN_CLIENT = '/admin/student/:id';
export const toAdminClient = (id: string) => `/admin/student/${id}`
export const ADMIN_BALANCE = '/admin/balance/:id';
export const toAdminBalance = (id: string) => `/admin/balance/${id}`
export const ADMIN_USERS = '/admin/users';
export const ADMIN_USER = '/admin/user/:id';
export const toAdminUser = (id: string) => `/admin/user/${id}`
export const ADMIN_ORDERS = '/admin/orders';


