import {useParams} from 'react-router';
import cn from 'classnames';

import {GroupEdit} from './Group/GroupEdit';
import {GroupNew} from './Group/GroupNew';

export const Group = () => {

    const {id} = useParams()

    return (
        <div className={cn(['content', 'admin-page'])}>
            {id === 'new' ?
                <GroupNew/>
                :
                <GroupEdit id={id!}/>
            }
        </div>
    )

}