import React, {FC} from 'react';
import {provider, useInstance} from 'react-ioc';
import {BrowserRouter as Router, Navigate, Route, Routes,} from 'react-router-dom';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import {ConfigProvider, Skeleton} from 'antd';
import ru_RU from 'antd/locale/ru_RU'
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru'
import {observer} from 'mobx-react-lite';

import { AboutMe } from '../components/AboutMe/AboutMe';
import ScrollToTop from '../components/common/ScrollToTop';
import {Layout} from '../components/layout/Layout';
import {LayoutAdmin} from '../components/layout/LayoutAdmin';
import {LoginLayout} from '../components/layout/LoginLayout';
import {
    ADMIN_BALANCE,
    ADMIN_CLIENT, ADMIN_CLIENTS,
    ADMIN_GROUP,
    ADMIN_GROUPS, ADMIN_ORDERS,
    ADMIN_USER,
    ADMIN_USERS,
    INDEX, INFO_CATERING, INFO_PAYMENT, LOGIN, PAYMENT_AWAIT,
} from '../config/routes';
import ApolloClientDI from '../model/graphql/ApolloClientDI';
import {Store} from '../model/store/Store';
import StoreDI from '../model/store/StoreDI';
import { Client } from '../pages/Admin/Clients/Client';
import {Balance} from '../pages/Admin/Clients/Client/Balance';
import {Clients} from '../pages/Admin/Clients/Clients';
import {Group} from '../pages/Admin/Groups/Group';
import {Groups} from '../pages/Admin/Groups/Groups';
import {Orders} from '../pages/Admin/Orders/Orders';
import { User } from '../pages/Admin/Users/User';
import {Users} from '../pages/Admin/Users/Users';
import {Index} from '../pages/Index/Index';
import {Catering} from '../pages/Info/Catering';
import { Policy } from '../pages/Info/Policy';
import {Login} from '../pages/Login/Login';
import {Await} from '../pages/Payment/Await';

import './App.scss';

const App: FC = () => {

    const store = useInstance(Store);
    const {hydrated, auth} = store;

    const apolloClient = useInstance(ApolloClient);

    dayjs.locale(ru)

    if (!hydrated) {
        return (
            <Skeleton active={true}/>
        );
    }
    return (
        <ConfigProvider locale={ru_RU} theme={{
            token: {
                colorPrimary: '#37779B',
                fontFamily: 'SB Sans, sans-serif',
                fontSize: 16,
            },
        }}>
            <ApolloProvider client={apolloClient}>
                <Router>
                    <ScrollToTop/>

                    {!auth.hasAuthToken && (
                        <LoginLayout>
                            <Routes>
                                <Route path={LOGIN} element={<Login/>}/>
                                <Route path="*" element={<Navigate to={LOGIN}/>}/>
                            </Routes>
                        </LoginLayout>
                    )}

                    {auth.hasAuthToken && <AboutMe/>}

                    {auth.role === 'Authenticated' && (
                        <Layout>
                            <Routes>
                                <Route path={INDEX} element={<Index/>}/>
                                <Route path={INFO_CATERING} element={<Catering/>}/>
                                <Route path={INFO_PAYMENT} element={<Policy/>}/>
                                <Route path={PAYMENT_AWAIT} element={<Await/>}/>
                                <Route path="*" element={<Navigate to={INDEX}/>}/>
                            </Routes>
                        </Layout>
                    )}
                    {auth.role === 'Admin' && (
                        <LayoutAdmin>
                            <Routes>
                                <Route path={INDEX} element={<Index/>}/>
                                <Route path={INFO_CATERING} element={<Catering/>}/>
                                <Route path={INFO_PAYMENT} element={<Policy/>}/>
                                <Route path={PAYMENT_AWAIT} element={<Await/>}/>
                                <Route path={ADMIN_GROUPS} element={<Groups/>}/>
                                <Route path={ADMIN_GROUP} element={<Group/>}/>
                                <Route path={ADMIN_CLIENTS} element={<Clients/>}/>
                                <Route path={ADMIN_CLIENT} element={<Client/>}/>
                                <Route path={ADMIN_BALANCE} element={<Balance/>}/>
                                <Route path={ADMIN_USERS} element={<Users/>}/>
                                <Route path={ADMIN_USER} element={<User/>}/>
                                <Route path={ADMIN_ORDERS} element={<Orders/>}/>
                                <Route path="*" element={<Navigate to={INDEX}/>}/>
                            </Routes>
                        </LayoutAdmin>
                    )}


                </Router>
            </ApolloProvider>
        </ConfigProvider>
    );
};

export default provider(
    StoreDI(),
    ApolloClientDI(),
)(observer(App));
