import {gql} from '@apollo/client';

export const QUERY_SELECT_CLIENTS = gql`
    query selectClients (
        $filters: ClientFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []
    ) {
        clients(filters: $filters, pagination: $pagination, sort: $sort) {
            documentId
            firstName
            lastName
            middleName
        }
    }
`;

export const QUERY_CLIENTS = gql`
    query clients (
        $filters: ClientFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []
    ) {
        clients(filters: $filters, pagination: $pagination, sort: $sort) {
            documentId
            firstName
            lastName
            middleName
            phone
            enabled
            birthday
            group {
                documentId
                name
            }
            parents {
                documentId
                firstName
                lastName
            }
        }
    }
`;

export const QUERY_CLIENT = gql`
    query client ($documentId: ID!,) {
        client(documentId: $documentId) {
            documentId
            firstName
            lastName
            middleName
            phone
            enabled
            birthday
            group {
                documentId
                name
            }
            parents {
                documentId
                firstName
                lastName
            }
        }
    }
`;

export const UPDATE_CLIENT = gql`
    mutation updateClient ($id: ID!, $data: ClientInput!) {
        updateClient(documentId: $id, data: $data) {
            documentId
        }
    }
`

export const CREATE_CLIENT = gql`
    mutation createClient ($data: ClientInput!) {
        createClient(data: $data) {
            documentId
        }
    }
`

