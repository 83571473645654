import {useInstance} from 'react-ioc';
import {useQuery} from '@apollo/client';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import {ICLient} from '../../model/store/ClientStore';
import { Store } from '../../model/store/Store';
import { me } from '../../shared/graphql/__generated__/me';
import {QUERY_ME} from '../../shared/graphql/login';

export const AboutMe = observer(() => {

    const {auth, client} = useInstance(Store)

    useQuery<me>(QUERY_ME, {
        variables: {ID: auth.id!},
        onCompleted: (data) => {
            auth.setRoleAndData(
                data.me?.role?.name!,
                {
                    firstName: data.me?.firstName!,
                    lastName: data.me?.lastName!,
                }
            )
            if (data.me?.clients) {
                const clientStoreData: ICLient[] = _.map(data.me?.clients, client => ({
                    id: client?.documentId!,
                    firstName: client?.firstName!,
                    lastName: client?.lastName!,
                    phone: client?.phone!,
                    group: client?.group?.name!,
                    birthday: client?.birthday,
                }))
                client.setClients(clientStoreData)
            }
        }
    })

    return <></>

})