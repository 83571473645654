import {useParams} from 'react-router';
import {useQuery} from '@apollo/client';

import {client, clientVariables} from '../../../../shared/graphql/admin/__generated__/client';
import {QUERY_CLIENT} from '../../../../shared/graphql/admin/clients';
import {Card, IUserData} from '../../../Index/Card';

export const Balance = () => {

    const {id} = useParams()

    const query = useQuery<client,clientVariables>(QUERY_CLIENT, {
        variables: {
            documentId: id!,
        },
    })

    const data:IUserData = {
        id: id!,
        phone: query.data?.client?.phone!,
        group: query.data?.client?.group?.name!,
        firstName: query.data?.client?.firstName!,
        lastName: query.data?.client?.lastName!,
        birthday: query.data?.client?.birthday!,

    }

    return (
        <Card data={data}/>
    )
}