import {useParams} from 'react-router';
import cn from 'classnames';

import {UserEdit} from './User/UserEdit';
import {UserNew} from './User/UserNew';

export const User = () => {

    const {id} = useParams()

    return (
        <div className={cn(['content', 'admin-page'])}>
            {id === 'new' ?
                <UserNew/>
                :
                <UserEdit id={id!}/>
            }
        </div>
    )

}