

import cls from './Empty.module.scss'


export const Empty = () => (
        <>
            <div className={cls.wrapper}>
                <div className={'content'}>
                    <div className={cls.title}>
                        <span className={cls.name}>Не найдено</span>
                    </div>
                </div>
            </div>
            <div className={'content'}>

                <div className={cls.page}>
                    <div>К вашему аккаунту не привязано ни одного ребёнка.</div>
                    <div>Обратитесь к Администратору.</div>
                </div>
                
            </div>

        </>
    )