import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import {Link, useSearchParams} from 'react-router-dom';
import {
    CheckCircleOutlined,
    EditOutlined,
    LockOutlined,
    NumberOutlined,
    PlusOutlined, ReloadOutlined,
    UnlockOutlined, WhatsAppOutlined
} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, Form, Input, Popconfirm, Space, Table, Tag, Tooltip} from 'antd';
import {useForm} from 'antd/es/form/Form';
import _ from 'lodash';

import {MyModal} from '../../../components/common/MyModal';
import {toAdminClient, toAdminUser} from '../../../config/routes';
import {groups_groups_clients} from '../../../shared/graphql/admin/__generated__/groups';
import {updateUser, updateUserVariables} from '../../../shared/graphql/admin/__generated__/updateUser';
import {
    users,
    users_usersPermissionsUsers_role,
    usersVariables
} from '../../../shared/graphql/admin/__generated__/users';
import {QUERY_USERS, UPDATE_USER} from '../../../shared/graphql/admin/users';
import generatePassword from '../../../shared/helpers/generatePassword';
import {pwdRegex} from '../../../shared/helpers/regex';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters} from './filter';

import cls from './Users.module.scss'

export const Users = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);
    const navigate = useNavigate();

    const [openSuccess, setOpenSuccess] = useState<boolean>(false)
    const [openPwd, setOpenPwd] = useState<boolean>(false)
    const [activeUser, setActiveUser] = useState<any>(undefined)

    const [form] = useForm()

    const [updateUser, {loading}] = useMutation<updateUser, updateUserVariables>(UPDATE_USER)

    const query = useQuery<users, usersVariables>(QUERY_USERS, {
        variables: {
            filters: filtersToQuery(filters),
            pagination: {limit: 1000},
            sort: ['blocked:ASC', 'role.name:ASC', 'lastName:ASC'],
        },
        fetchPolicy: 'no-cache',
    })

    const onSearchChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const dataSource = _.map(query.data?.usersPermissionsUsers, item => ({
        ...item,
        key: item?.documentId,
        name: `${item?.firstName} ${item?.lastName}`,
    }))

    const updateUserBlocked = async (id: string, blocked: boolean) => {
        await updateUser({
            variables: {id: id, data: {blocked: blocked}},
            onCompleted: () => {
                query.refetch()
            }
        })
    }

    const updateUserPwd = async (data:any) => {
        await updateUser({
            variables: {id: activeUser.documentId, data: {password: data.password}},
            onCompleted: () => {
                setOpenPwd(false)
                setActiveUser({
                    ...activeUser,
                    password: data.password,
                    message: `Новый пароль для входа

Логин: ${activeUser.username}
Пароль: ${data.password}`
                })
                setOpenSuccess(true)
            }
        })
    }

    const columns = [
        {
            title: 'Имя Фамилия',
            dataIndex: 'name',
            key: 'name',
            render: (item: any, record: any) => (
                !record.blocked ? item : <span className={cls.hided}>{item}</span>
            ),
        },
        {
            title: 'Логин',
            dataIndex: 'username',
            key: 'username',
            render: (item: any, record: any) => (
                !record.blocked ? item : <span className={cls.hided}>{item}</span>
            ),
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            key: 'role',
            render: (role: users_usersPermissionsUsers_role) => {
                if (role.name === 'Admin') return 'Администратор'
                if (role.name === 'Authenticated') return 'Родитель'
                return '—'
            },
        },
        {
            title: 'Дети',
            dataIndex: 'clients',
            key: 'clients',
            render: (clients: groups_groups_clients[]) => (
                <>{_.map(clients, client =>
                    <Link key={client.documentId} to={toAdminClient(client.documentId)}>
                        <Tag >
                            {client.firstName} {client.lastName}
                        </Tag>
                    </Link>
                )}
                </>),
        },
        {
            key: 'control',
            render: (_: any, record: any) => (
                <Space className={record.blocked && cls.hided}>
                    <Tooltip title={'Редактировать'}>
                        <Button type={'link'} onClick={() => navigate(toAdminUser(record.documentId))}
                                icon={<EditOutlined/>}/>
                    </Tooltip>
                    {!record.blocked ?
                        <Tooltip title={'Заблокировать'}>
                            <Popconfirm
                                title="Блокировка доступа"
                                description={'Вы уверены что хотите заблокировать пользователя?'}
                                onConfirm={() => updateUserBlocked(record.documentId, true)}
                                okText="Да"
                                cancelText="Нет"
                            >
                                <Button type={'link'} icon={<UnlockOutlined/>}/>
                            </Popconfirm>
                        </Tooltip>
                        :
                        <Tooltip title={'Разблокировать'}>
                            <Button type={'link'} onClick={() => updateUserBlocked(record.documentId, false)}
                                    icon={<LockOutlined/>}/>
                        </Tooltip>
                    }
                    <Tooltip title={'Сменить пароль'}>
                        <Button type={'link'} onClick={() => {
                            setActiveUser(record);
                            setOpenPwd(true);
                        }}
                                icon={<NumberOutlined/>}/>
                    </Tooltip>
                </Space>
            )
        },

    ];

    return (
        <div className={'admin-page'}>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <Input.Search placeholder={'Поиск...'} onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <Button type={'primary'} icon={<PlusOutlined/>}
                            onClick={() => navigate(toAdminUser('new'))}>Добавить</Button>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}/>
            <MyModal isOpen={openPwd} onClose={() => setOpenPwd(false)} className={cls.modal}>
                <Space direction={'vertical'}>
                    <Form layout={'vertical'} size={'large'} initialValues={{password: generatePassword()}} onFinish={updateUserPwd} disabled={loading}
                          form={form}>
                    <Form.Item name={'password'} label={'Пароль'} rules={[
                        {required: true, message: 'Заполните поле'},
                        {pattern: pwdRegex, message: 'Не соответствует формату пароля'}
                    ]}
                               extra={'Минимум 6 латинских букв и цифр'}
                    >
                        <Input
                            placeholder={'12345678'}
                            suffix={
                                <Button
                                    size={'small'}
                                    type={'text'}
                                    icon={<ReloadOutlined/>}
                                    onClick={() => form.setFieldValue('password', generatePassword())}
                                />}
                        />
                    </Form.Item>
                        <Form.Item>
                            <Button htmlType={'submit'} type={'primary'} loading={loading}>
                                Установить пароль
                            </Button>
                        </Form.Item>
                    </Form>
                </Space>
            </MyModal>
            <MyModal isOpen={openSuccess} onClose={() => setOpenSuccess(false)} className={cls.modal}>
                <Space direction={'vertical'}>
                    <div className={cls.icon}>
                        <CheckCircleOutlined/> Успешно
                    </div>

                    <div>
                        Пароль виден только на этом экране, посмотреть повторно не получится, но можно установить новый.
                        Отправьте сообщение с новым логином и паролем.
                    </div>

                    <pre className={cls.message}>
                        {activeUser && activeUser.message}
                    </pre>

                    <a href={encodeURI(`https://api.whatsapp.com/send/?phone=${activeUser && activeUser.username}&text=${activeUser && activeUser.message}`)} target={'_blank'}
                       rel={'noreferrer'}>
                        <Button type={'primary'} size={'large'} onClick={() => setOpenSuccess(false)}
                                icon={<WhatsAppOutlined/>}>Написать в WhatsApp</Button>
                    </a>

                </Space>
            </MyModal>

        </div>
    )

}