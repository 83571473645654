import {Link} from 'react-router-dom'
import cn from 'classnames'

import {INFO_CATERING, INFO_PAYMENT} from '../../config/routes';

import cls from './Layout.module.scss'

export const Footer = () => (
    <div className={cls.footer}>
        <div className={cn(['content', cls.content])}>
            <div className={cls.bold}>Юридическая информация</div>
            <Link to={INFO_CATERING}>Организация питания</Link>
            <Link to={INFO_PAYMENT}>Политика обработки персональных данных</Link>
        </div>
    </div>
)