import React from 'react';
import cn from 'classnames'

import {DisplayData} from '../../components/common/DisplayData';

import cls from './Catering.module.scss'
export const Catering = () => (

            <div className={cn(['content', cls.page])}>
                <DisplayData
                    label="Юридическое лицо"
                    value="ООО «Санфуд»"
                />
                <DisplayData label="ИНН" value="5404007353" />
                <DisplayData label="ОГРН" value="1155476029862" />
                <DisplayData
                    label="Адрес регистрации"
                    value="630048,  г. Новосибирск, пл. Карла Маркса, 7"
                />
                <DisplayData
                    label="Фактический адрес"
                    value="630009, Новосибирская обл, г. Новосибирск, ул. Обская, дом № 48/1"
                />
                <DisplayData
                    label="Электронная почта"
                    value={
                        <a href={'mailto:e.styadya@yandex.ru'}>
                            e.styadya@yandex.ru
                        </a>
                    }
                />
                <DisplayData
                    label="Номер телефона"
                    value="+7 (953) 885-00-75"
                />
            </div>
    )

