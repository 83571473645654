import { DatePicker} from 'antd';
import {Dayjs} from 'dayjs';

interface Props {
    value: Dayjs;
    onChange?: (value: Dayjs) => void;
}


export const MyDatePicker = ({value, onChange}: Props) => {

    console.log('value', value)

    return (
        <>
            {/*<Button onClick={() => setOpen(true)} type={'link'}>*/}
            {/*    {value.format('DD MMM YYYY')}*/}
            {/*</Button>*/}
            <DatePicker value={value} onChange={onChange} format={'DD.MM.YYYY'} variant={'filled'} allowClear={false}/>
        </>
    )
}