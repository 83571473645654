import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {Button, Input, Space, Table, Tag} from 'antd';
import _ from 'lodash';

import {toAdminGroup} from '../../../config/routes';
import {
    groups,
    groups_groups_clients,
    groupsVariables
} from '../../../shared/graphql/admin/__generated__/groups';
import {QUERY_GROUPS} from '../../../shared/graphql/admin/groups';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters} from './filter';

import cls from './Groups.module.scss'

export const Groups = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);
    const navigate = useNavigate();

    const query = useQuery<groups, groupsVariables>(QUERY_GROUPS, {
        variables: {
            filters: filtersToQuery(filters),
            pagination: {limit: 1000},
            sort: ['name:ASC'],
            filtersClients: {enabled: {eq: true}},
            paginationClients: {limit: 1000},
            sortClients: ['lastName:ASC'],
        },
        fetchPolicy: 'no-cache',
    })

    const onSearchChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const dataSource = _.map(query.data?.groups, item => ({
        ...item,
        key: item?.documentId,
    }))

    const columns = [
        {
            title: 'Класс',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Ученики',
            dataIndex: 'clients',
            key: 'clients',
            render: (clients: groups_groups_clients[]) => (
                <>{_.map(clients, client =>
                    <Tag key={client.documentId}>
                        {client.firstName} {client.lastName}
                    </Tag>
                )}
                </>),
        },
        {
            key: 'control',
            render: (_:any, record:any) => (
                <Space>
                    <Button type={'link'} onClick={() => navigate(toAdminGroup(record.documentId))} icon={<EditOutlined/>}/>
                </Space>
            )
        },

    ];

    return (
        <div className={'admin-page'}>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <Input.Search placeholder={'Поиск...'} onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <Button type={'primary'} icon={<PlusOutlined/>} onClick={() => navigate(toAdminGroup('new'))}>Добавить</Button>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}/>

        </div>
    )

}