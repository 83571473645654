import React from 'react';

import policyPDF from './policyPDF.pdf';

import cls from './Policy.module.scss'
export const Policy = () => (
            <iframe
                src={`${policyPDF}#view=fitH`}
                title={'Политика обработки  персональных данных'}
                className={cls.page}
            ></iframe>
    );