import React from 'react';
import {useInstance} from 'react-ioc';
import {Carousel} from 'antd';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';

import {Store} from '../../model/store/Store';

import {Card} from './Card';
import {Empty} from './Empty';

import cls from './Index.module.scss'


export const Index = observer(() => {

    const {client} = useInstance(Store)

    if (client.clients.length > 1) {

        return (
            <Carousel draggable adaptiveHeight dotPosition={'top'}>
                {_.map(client.clients, item => (
                    <div key={item.id} style={{minHeight: 500}}>
                        {client.clients.length > 1 && <div className={cls.control}/>}
                        <Card data={item}/>
                    </div>
                ))}
            </Carousel>
        )
    } else if (client.clients.length > 0) {
        return <Card data={client.clients[0]}/>
    } else {
        return (
            <Empty/>
        )
    }
})