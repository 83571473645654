import {gql} from '@apollo/client';

export const QUERY_SELECT_USERS = gql`
    query selectUsers (
        $filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []
    ) {
        usersPermissionsUsers(filters: $filters, pagination: $pagination, sort: $sort) {
            documentId
            firstName
            lastName
            middleName
        }
    }
`;

export const QUERY_USERS = gql`
    query users (
        $filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []
    ) {
        usersPermissionsUsers(filters: $filters, pagination: $pagination, sort: $sort) {
            documentId
            username
            firstName
            lastName
            middleName
            blocked
            role {
                documentId
                name
            }
            clients {
                documentId
                firstName
                lastName
            }
        }
    }
`;

export const QUERY_USER = gql`
    query user ($documentId: ID!) {
        usersPermissionsUser(documentId: $documentId) {
            documentId
            username
            firstName
            lastName
            middleName
            blocked
            role {
                documentId
                name
            }
            clients {
                documentId
                firstName
                lastName
            }
        }
    }
`;

export const UPDATE_USER = gql`
    mutation updateUser ($id: ID!, $data: UsersPermissionsUserInput!) {
        updateUsersPermissionsUser(id: $id, data: $data) {
            data {
                documentId
            }
        }
    }
`

export const CREATE_USER = gql`
    mutation createUser ($data: UsersPermissionsUserInput!) {
        createUsersPermissionsUser(data: $data) {
            data {
                documentId
            }
        }
    }
`

export const CHECK_LOGIN = gql`
    query checkLogin ($username: String!) {
        checkLogin(username: $username)
    }
`