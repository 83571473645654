import React from 'react';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import type {SelectProps} from 'antd'
import {Button, Form, Input, Select} from 'antd'
import _ from 'lodash';

import {selectClients, selectClientsVariables} from '../../../../shared/graphql/admin/__generated__/selectClients';
import {QUERY_SELECT_CLIENTS} from '../../../../shared/graphql/admin/clients';

import cls from './Group.module.scss'


export interface IGroup {
    name: string
    clients: string[]
}

export interface IFormGroup {
    values: IGroup
    action: string
    onFinish: (data: IGroup) => void
    loading?: boolean
}


export const GroupForm = ({values, action, onFinish, loading}: IFormGroup) => {

    const clients = useQuery<selectClients, selectClientsVariables>(QUERY_SELECT_CLIENTS, {
        variables: {
            filters: {enabled: {eq: true},},
            pagination: {limit: 5000},
        }
    })

    const clientsOptions: SelectProps['options'] = _.map(clients.data?.clients, client => ({
        value: client?.documentId,
        label: `${client?.firstName} ${client?.lastName}`,
    }))

    return (
        <Form layout={'vertical'} size={'large'} initialValues={values} onFinish={onFinish} disabled={loading}>

            <Form.Item name={'name'} label={'Название'} rules={[
                {required: true, message: 'Заполните поле'},
            ]}>
                <Input placeholder={'1А'}/>
            </Form.Item>

            <Form.Item label={'Ученики'}>

                <Form.List name={'clients'}>
                    {(fields, {add, remove}, {errors}) => (
                        <>
                            {fields.map((field) => (
                                <React.Fragment key={field.key}>
                                    <div className={cls.client}>

                                        <Form.Item className={cls.select}
                                                   {...field}
                                                   rules={[
                                                       {
                                                           required: true,
                                                           message: 'Заполните поле',
                                                       },
                                                   ]}
                                        >
                                            <Select options={clientsOptions}/>
                                        </Form.Item>
                                        <div className={cls.minus}>
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(field.name)}
                                            />
                                        </div>
                                    </div>

                                </React.Fragment>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    icon={<PlusOutlined/>}
                                />
                                <Form.ErrorList errors={errors}/>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

            </Form.Item>


            <Form.Item>
                <Button htmlType={'submit'} type={'primary'} loading={loading}>
                    {action}
                </Button>
            </Form.Item>

        </Form>
    )
}