import {useNavigate} from 'react-router';
import {ClockCircleOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import cn from 'classnames';

import { INDEX } from '../../config/routes';

import cls from './Payment.module.scss';

export const Await = () => {

    const navigate = useNavigate()

    return (
        <div className={cn(['content', cls.page])}>
            <ClockCircleOutlined className={cls.icon}/>
            <div className={'title'}>Платеж в обработке</div>
            <div>Большое спасибо!</div>
            <div>При успешной оплате деньги поступят на кошелёк через несколько минут.</div>
            <Button type={'primary'} size={'large'} onClick={() => navigate(INDEX)} block>Хорошо</Button>
        </div>
    )
}