import {gql} from '@apollo/client';

export const QUERY_ADMIN_ORDERS = gql`
    query adminOrders (
        $filters: OrderFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []
    ) {
        orders(filters: $filters, pagination: $pagination, sort: $sort) {
            createdAt
            documentId
            UID
            phone
            amount
            client {
                documentId
                firstName
                lastName
            }
            paymentStatus
            iikoStatus
        }
    }
`;