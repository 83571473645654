import {useParams} from 'react-router';
import cn from 'classnames';

import {ClientEdit} from './Client/ClientEdit';
import {ClientNew} from './Client/ClientNew';

export const Client = () => {

    const {id} = useParams()

    return (
        <div className={cn(['content', 'admin-page'])}>
            {id === 'new' ?
                <ClientNew/>
                :
                <ClientEdit id={id!}/>
            }
        </div>
    )

}