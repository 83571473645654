import {gql} from '@apollo/client';

export const QUERY_GROUPS = gql`
    query groups (
        $filters: GroupFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []
        $filtersClients: ClientFiltersInput, $paginationClients: PaginationArg = {}, $sortClients: [String] = []
    ) {
        groups(filters: $filters, pagination: $pagination, sort: $sort) {
            documentId
            name
            clients (filters: $filtersClients, pagination: $paginationClients, sort: $sortClients) {
                documentId
                firstName
                lastName
            }
        }
    }
`;

export const QUERY_SELECT_GROUPS = gql`
    query selectGroups (
        $filters: GroupFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []
    ) {
        groups(filters: $filters, pagination: $pagination, sort: $sort) {
            documentId
            name
        }
    }
`;

export const QUERY_GROUP = gql`
    query group (
        $documentId: ID!, $filtersClients: ClientFiltersInput, $paginationClients: PaginationArg = {}, $sortClients: [String] = []
    ) {
        group(documentId: $documentId) {
            documentId
            name
            clients (filters: $filtersClients, pagination: $paginationClients, sort: $sortClients) {
                documentId
                firstName
                lastName
                enabled
            }
        }
    }
`;

export const CREATE_GROUP = gql`
    mutation createGroup ($data: GroupInput!) {
        createGroup(data: $data) {
            documentId
        }
    }
`

export const UPDATE_GROUP = gql`
    mutation updateGroup ($id: ID!, $data: GroupInput!) {
        updateGroup(documentId: $id, data: $data) {
            documentId
        }
    }
`