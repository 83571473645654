import {gql} from '@apollo/client';
export const MUTATION_LOGIN = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
        login(input: $input) {
            jwt
            user {
                id
                blocked
            }
        }
    }
`;

export const QUERY_ME = gql`
    query me {
        me {
            id
            firstName
            lastName
            role {
                id
                name
            }
            clients {
                documentId
                firstName
                lastName
                phone
                birthday
                group {
                    documentId
                    name
                }
            }
        }
    }
`;