import {useNavigate} from 'react-router';
import {useMutation, useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import _ from 'lodash';

import {group, groupVariables} from '../../../../shared/graphql/admin/__generated__/group';
import {updateGroup, updateGroupVariables} from '../../../../shared/graphql/admin/__generated__/updateGroup';
import { QUERY_GROUP, UPDATE_GROUP} from '../../../../shared/graphql/admin/groups';

import {GroupForm, IGroup} from './GroupForm';

interface IGroupEdit {
    id: string
}

export const GroupEdit = ({id}: IGroupEdit) => {

    const query = useQuery<group,groupVariables>(QUERY_GROUP, {
        variables: {documentId: id}
    })

    const initValues:IGroup = {
        name: query.data?.group?.name!,
        clients: _.map(query.data?.group?.clients, client => client?.documentId!),
    }

    const [updateGroup] = useMutation<updateGroup,updateGroupVariables>(UPDATE_GROUP)
    const navigate = useNavigate()

    const onFinish = async (data: IGroup) => {
        await updateGroup({
            variables: {
                id: id,
                data: data,
            },
            onCompleted: () => {
                navigate(-1)
            },
            fetchPolicy: 'no-cache',
        })
    }

    if (query.loading) return <Skeleton active/>

    return <GroupForm values={initValues} action={'Сохранить'} onFinish={onFinish}/>

}