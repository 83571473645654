import {useState} from 'react';
import {useInstance} from 'react-ioc';
import { useMutation} from '@apollo/client';
import {Alert, Button, Form, Input} from 'antd';
import {observer} from 'mobx-react-lite';

import { Store } from '../../model/store/Store';
import {login, loginVariables} from '../../shared/graphql/__generated__/login';
import {MUTATION_LOGIN} from '../../shared/graphql/login';

import logo from './Logo.svg'

import cls from './Login.module.scss'


interface ILoginData {
    identifier: string
    password: string
}

export const Login = observer(() => {

    const [invalidPair, setInvalidPair] = useState<boolean>(false)
    const [blockedUser, setBlockedUser] = useState<boolean>(false)

    const [login] = useMutation<login, loginVariables>(MUTATION_LOGIN)
    const {auth} = useInstance(Store);
    const onFinish = async (data: ILoginData) => {
        await login({
            variables: {
                input: {
                    identifier: data.identifier,
                    password: data.password,
                    provider: 'local',
                }
            },
            onCompleted: async (data) => {
                if (data.login.user.blocked) {
                    setBlockedUser(true)
                } else {
                    auth.setTokenAndId(data.login.jwt!, data.login.user.id!)
                }
            },
            onError: (error) => {
                console.log(error.graphQLErrors)

                const errorMessage = error.graphQLErrors[0].extensions.error.message

                if (errorMessage === 'Invalid identifier or password') {
                    setInvalidPair(true)
                }
                if (errorMessage === 'Your account has been blocked by an administrator') {
                    setBlockedUser(true)
                }
            }
        })
    }

    return (
        <div>

            <img src={logo} alt={'Logo'} className={cls.logo}/>
            
            <Form layout={'vertical'} onFinish={onFinish} size={'large'}>

                {invalidPair && (
                    <Form.Item>
                        <Alert message={'Не верный логин и/или пароль'} type={'error'}/>
                    </Form.Item>
                )}

                {blockedUser && (
                    <Form.Item>
                        <Alert message={'Ваш аккаунт заблокирован администратором.'} type={'error'}/>
                    </Form.Item>
                )}

                <Form.Item name={'identifier'} label={'Логин'}
                           rules={[
                               {required: true, message: 'Заполните поле'},
                           ]}

                >
                    <Input/>
                </Form.Item>

                <Form.Item name={'password'} label={'Пароль'}
                           rules={[
                               {required: true, message: 'Заполните поле'},
                           ]}

                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item>
                    <Button htmlType={'submit'} type={'primary'} block>Войти</Button>
                </Form.Item>
                
            </Form>
        </div>
    )
})