import {useState} from 'react';
import {ClockCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Alert, Button, Form, InputNumber, Skeleton} from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';

import {ENUM_ORDER_PAYMENTSTATUS} from '../../__generated__/global-types';
import {MyDatePicker} from '../../components/common/MyDatePicker';
import {MyModal} from '../../components/common/MyModal';
import {MUTATION_CREATE_ORDER, QUERY_IIKO_BALANCE, QUERY_IIKO_TRANSACTIONS, QUERY_ORDERS} from '../../shared/graphql';
import {createOrder, createOrderVariables} from '../../shared/graphql/__generated__/createOrder';
import {iikoBalance, iikoBalanceVariables} from '../../shared/graphql/__generated__/iikoBalance';
import {iikoTransactions, iikoTransactionsVariables} from '../../shared/graphql/__generated__/iikoTransactions';
import {orders, ordersVariables} from '../../shared/graphql/__generated__/orders';
import {currencyFormat} from '../../shared/helpers/currencyFormat';
import {phonePlainToInput} from '../../shared/helpers/phoneFormat';

import cls from './Index.module.scss'

interface IForm {
    amount: number
}

export interface IUserData {
    id: string
    phone: string
    group?: string | null
    firstName: string
    lastName: string
    birthday?: string | null
}

interface ICard {
    data: IUserData
}

export const Card = ({data}:ICard) => {

    const [range, setRange] = useState([dayjs().startOf('week'), dayjs().endOf('week')])
    const [open, setOpen] = useState<boolean>(false)
    const [noBalance, setNoBalance] = useState<boolean>(true)

    const [createOrder, createOrderData] = useMutation<createOrder, createOrderVariables>(MUTATION_CREATE_ORDER)

    const userData = {
        // @ts-ignore
        id: data.id,
        // @ts-ignore
        firstName: data.firstName,
        // @ts-ignore
        lastName: data.lastName,
        // @ts-ignore
        phone: data.phone,
        // @ts-ignore
        group: data.group,

        birthday: data.birthday,
    }
    // console.log('data', data?.firstName!)

    const balance = useQuery<iikoBalance, iikoBalanceVariables>(QUERY_IIKO_BALANCE, {
        variables: {
            phone: userData.phone
        },
        onCompleted: (data) => {
            if (data.iikoBalance.blocked || data.iikoBalance.deleted) {
                setNoBalance(true)
            } else {
                setNoBalance(false)
            }
        },
        onError: () => {
            setNoBalance(true)
        }
    })

    const orders = useQuery<orders,ordersVariables>(QUERY_ORDERS, {
        variables: {
            filters: {
                client: {documentId: {eq: userData.id}},
                createdAt: {gte: dayjs().subtract(20, 'minutes').format()},
                paymentStatus: {in: [ENUM_ORDER_PAYMENTSTATUS.CREATED_1]},
            }
        },
        skip: noBalance,
    })
    const transactions = useQuery<iikoTransactions, iikoTransactionsVariables>(QUERY_IIKO_TRANSACTIONS, {
        variables: {
            data: {
                phone: userData.phone,
                dateFrom: range[0].format('YYYY-MM-DD'),
                dateTo: range[1].format('YYYY-MM-DD'),
            }
        },
        skip: noBalance,
    })

    const transactiondData = _.groupBy(transactions.data?.iikoTransactions, item => dayjs(item?.transactionDate).format('DD MMM YYYY'))

    const onFinish = async (values: IForm) => {
        await createOrder({
            variables: {
                data: {
                    amount: values.amount,
                    phone: userData.phone,
                    client: userData.id,
                }
            },
            onCompleted: (data) => {
                const paymentLink:string = data.createOrder?.paymentLink!
                window.location.assign(paymentLink);            }
        })
    }

    return (
        <>
            <div className={cls.wrapper}>
                <div className={'content'}>
                    <div className={cls.title}>
                        <span className={cls.name}>{userData.firstName} {userData.lastName}</span>
                    </div>
                    {userData.birthday && <span className={cls.name}>{dayjs(userData.birthday).format('DD.MM.YYYY')}</span>}
                </div>
            </div>
            <div className={'content'}>
                <div className={cls.balance}>
                    <div className={cls.bold}>Баланс питания</div>
                    <div className={cls.value}>
                        {balance.loading ? '...' :
                            `${currencyFormat(balance.data?.iikoBalance.balance!)} ₽`
                        }
                    </div>
                    <div className={cls.phone}>{phonePlainToInput(userData.phone)}</div>
                    <Button type={'primary'} size={'large'} icon={<PlusOutlined/>}
                            loading={balance.loading} disabled={noBalance} onClick={() => setOpen(true)}>Пополнить</Button>
                </div>

                {orders.data?.orders.length! > 0 &&
                    <div className={cls.orders}>
                        <div className={cls.bold}>Ожидает пополнения</div>
                        {_.map(orders.data?.orders, order => (
                            <div className={cls.row} key={order?.documentId!}>
                                <div className={cls.time}>{dayjs(order?.createdAt).format('HH:mm')}</div>
                                <div className={cls.comment}>Пополнение баланса</div>
                                <div className={cls.sum}>{currencyFormat(order?.amount!)} ₽ <ClockCircleOutlined/></div>
                            </div>
                        ))}
                    </div>
                }

                {balance.data?.iikoBalance.deleted && <Alert className={cls.alert} type={'error'} message={'Кошелёк удалён. Обратитесь к администратору'}/>}
                {balance.data?.iikoBalance.blocked && <Alert className={cls.alert} type={'error'} message={'Кошелёк заблокирован. Обратитесь к администратору'}/>}

                <div className={cls.transactions}>
                    <div className={cls.bold}>История транзакций</div>
                    <div className={cls.range}>
                        <div><MyDatePicker value={range[0]} onChange={(value) => setRange([value, range[1]])}/></div>
                        <div>—</div>
                        <div><MyDatePicker value={range[1]} onChange={(value) => setRange([range[0], value])}/></div>
                    </div>
                    {transactions.loading && <Skeleton active={true}/>}
                    {_.map(transactiondData, (group, key) => (
                        <div className={cls.date} key={key}>
                            <div>{key}</div>
                            {_.map(group, item => (
                                <div className={cls.row} key={item?.orderNumber}>
                                    <div className={cls.time}>{dayjs(item?.transactionDate).format('HH:mm')}</div>
                                    <div className={cls.comment}>{item?.type}</div>
                                    <div
                                        className={cn([cls.sum, item?.transactionSum! > 0 && cls.plus])}>{item?.transactionSum! > 0 && '+'}{currencyFormat(item?.transactionSum!)} ₽
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            <MyModal isOpen={open} onClose={() => setOpen(false)}>
                <div className={'title'}>
                    Пополнить баланс
                </div>
                <Form layout={'vertical'} onFinish={onFinish} size={'large'} initialValues={{amount: 1000}}
                      disabled={createOrderData.loading}>
                    <Form.Item name={'amount'} label={'Сумма пополнения'}
                               rules={[{required: true, message: 'Заполните поле'}]}>
                        <InputNumber className={'block'} suffix={'₽'} precision={2}
                                     formatter={(value) =>
                                         `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                     }
                                     parser={(value) => value!.replace(/[₽\s]/g, '')}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType={'submit'} type={'primary'} block loading={createOrderData.loading}>Перейти к
                            оплате</Button>
                    </Form.Item>
                </Form>
            </MyModal>

        </>
    )
}